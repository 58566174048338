import Vue from '../../main.js';
import { apiUrl } from '@/app.config';
/**
 * Declare Variable
 */
const serviceUrl = apiUrl + '/role'
const allUrl = `${serviceUrl}`

/**
 * Export
 */
export default {
  async getAll() {
    return Vue.http.get(allUrl);
  },
}