<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md8>
      <material-card :title="title" icon="mdi-account-group">
        <v-card-text>
          <v-form>
            <v-row row>
              <v-col xs="5">
                <v-subheader class="label-left">Name/Nombre:</v-subheader>
              </v-col>
              <v-col xs="7">
                <v-text-field
                  solo
                  class="right-input"
                  v-model.trim="form.name"
                  :error-messages="nameErrors"
                  maxlength="40"
                  required
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="5">
                <v-subheader class="label-left">Email:</v-subheader>
              </v-col>
              <v-col xs="7">
                <v-text-field
                  solo
                  class="right-input"
                  v-model="form.email"
                  append-outer-icon="fas fa-at"
                  :error-messages="emailErrors"
                  maxlength="35"
                  required
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="5">
                <v-subheader class="label-left">Role/Rol:</v-subheader>
              </v-col>
              <v-col xs="7">
                <v-select
                  solo
                  class="right-input"
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  :error-messages="roleErrors"
                  v-model="form.roleID"
                  @input="$v.form.roleID.$touch()"
                  @blur="$v.form.roleID.$touch()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="5">
                <v-subheader class="label-left"
                  >Password/Contraseña:</v-subheader
                >
              </v-col>
              <v-col xs="7">
                <v-text-field
                  solo
                  class="right-input"
                  type="password"
                  append-outer-icon="fas fa-user-lock"
                  v-model.trim="form.password"
                  :error-messages="passwordErrors"
                  maxlength="30"
                  required
                  @input="$v.form.password.$touch()"
                  @blur="$v.form.password.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="5">
                <v-subheader class="label-left"
                  >Repeat password/Repetir contraseña:</v-subheader
                >
              </v-col>
              <v-col xs="7">
                <v-text-field
                  solo
                  class="right-input"
                  type="password"
                  prepend-icon="fas fa-user-lock"
                  v-model.trim="form.repeatPassword"
                  :error-messages="repeatPasswordErrors"
                  maxlength="30"
                  label=""
                  required
                  @input="$v.form.repeatPassword.$touch()"
                  @blur="$v.form.repeatPassword.$touch()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="align-center justify-center">
          <v-btn
            class="action"
            color="blue"
            v-on:click.prevent="save"
            :disabled="$v.$invalid"
            :loading="isLoading"
          >
            {{ button }}
            <v-icon right>fas fa-check-circle</v-icon>
          </v-btn>
          <v-btn @click="goBack" color="red"
            >Back/Regresar
            <v-icon right>fas fa-ban</v-icon>
          </v-btn>
        </v-card-actions>
      </material-card>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  required,
  email,
  sameAs,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import users from "../../services/api/users";
import roles from "../../services/api/roles";

export default {
  name: "user",
  components: {
    MaterialCard: () => import("@/components/base/MaterialCard"),
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        repeatPassword: "",
        name: "",
        role: "",
        roleID: null,
      },
      isCreate: true,
      roles: [],
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      password: {
        required: requiredIf("isCreate"),
        minLength: minLength(6),
      },
      email: {
        required,
        email,
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
      roleID: {
        required,
      },
    },
  },
  async created() {
    this.isCreate = this.type == "new";
    const response = await roles.getAll();
    this.roles = response.body;
    if (!this.isCreate) {
      const response = await users.get(this.$route.params.email);
      this.form = response.data;
    }
  },
  methods: {
    ...mapActions(["login"]),
    async save() {
      try {
        if (this.isCreate) {
          await users.save(this.form);
        } else {
          await users.update(this.form);
        }
        this.$router.push({
          name: "usersList",
        });
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    goBack() {
      this.$router.push({
        name: "usersList",
      });
    },
  },
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
    title() {
      return this.isCreate
        ? "NEW USER/USUARIO NUEVO"
        : "EDIT USER/EDITAR USUARIO";
    },
    button() {
      return this.isCreate ? "Create/Crear" : "Edit/Editar";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email &&
        errors.push("Invalid email/Email es inválido");
      !this.$v.form.email.required && errors.push("Required/Requerido");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Required/Requerido");
      !this.$v.form.password.minLength &&
        errors.push(
          `Must have at least ${this.$v.form.password.$params.minLength.min}/Longitud mínima ${this.$v.form.password.$params.minLength.min}`
        );
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.form.repeatPassword.$dirty) return errors;
      !this.$v.form.repeatPassword.sameAsPassword &&
        errors.push("Password must be identical/Contraseñas no coinciden");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Required/Requerido");
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.form.roleID.$dirty) return errors;
      !this.$v.form.roleID.required && errors.push("Required/Requerido");
      return errors;
    },
  },
};
</script>
<style scoped>
.row {
  padding-top: 0 !important;
}
</style>